.Toggle {
	@apply tw-flex tw-flex-nowrap tw-justify-center tw-w-full;
	outline-offset: 4px !important;
	&:disabled {
		.Toggle-switch {
			@apply tw-opacity-50;
		}
		&, * {
			@apply tw-cursor-not-allowed;
		}
	}
	.Toggle-label {
		@apply tw-text-orange tw-font-bold tw-no-underline tw-mr-3;
	}
	.Toggle-switch {
		@apply tw-bg-gray-400 tw-rounded-full tw-h-3 tw-relative;
		width: 36px;

		&::before {
			@apply tw-bg-white tw-border tw-border-solid tw-border-gray-400 tw-w-5 tw-h-5 tw-left-0 tw-absolute;
			border-radius: 50%;
			content: '';
			transform: translateX(0);
			transition: transform 200ms ease-in-out;
			top: -4px;
		}
		&.is-active {
			@apply tw-bg-orange tw-border-white;
			&::before {
				@apply tw-bg-orange tw-border-2 tw-border-solid tw-border-white;
				height: 22px;
				transform: translateX(calc(100% - 5px));
				top: -5px;
				width: 22px;
			}
		}
	}
}

.Toggle.Toggle--feature {
	&.has-overrides .Toggle-switch::before,
	.Toggle-switch.is-active::before {
		border-width: 2px;
	}
	&.has-overrides {
		.Toggle-switch {
			&,
			&::before,
			&.is-active,
			&.is-active::before {
				@apply tw-bg-orange tw-border-white;
			}
			&::before {
				width: 22px;
				height: 22px;
				top: -5px;
				transform: translateX(-3px);
			}
		}
	}
	.Toggle-switch.is-active {
		@apply tw-bg-navy tw-border-white;
		&::before {
			@apply tw-bg-navy tw-border-2 tw-border-solid tw-border-white;
			width: 22px;
			height: 22px;
			top: -5px;
			transform: translateX(calc(100% - 5px));
		}
	}
}