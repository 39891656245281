.overlay {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	z-index: var(--toolbar-zi-menus);
	background-color: rgba(0, 0, 0, 0.5);
}
.engageMenuOverlayEnter {
	opacity: 0.01;
}
.engageMenuOverlayEnter.engageMenuOverlayEnterActive,
.engageMenuOverlayEnterDone {
	opacity: 1;
	transition: opacity var(--toolbar-anim-speed) ease-in;
}
.engageMenuOverlayLeave {
	opacity: 1;
}
.engageMenuOverlayLeave.engageMenuOverlayLeaveActive {
	opacity: 0.01;
	transition: opacity var(--toolbar-anim-speed) ease-out 150ms;
}