.currentBuild {
	@apply tw-flex tw-gap-4;
	&.queued {

	}
	&.running {

	}
	&.finished {

	}
}
.badge {
	@apply tw-inline-flex tw-items-center tw-rounded-full tw-px-2.5 tw-py-0.5 tw-text-xs tw-font-bold;
	&.queued {
		@apply tw-bg-gray-500 tw-font-bold tw-text-gray-200;
	}
	&.finished {
		@apply tw-bg-green-100;
		color: #166534;
	}
	&.running {
		background-color: #fef9c3;
		color: #854d0e;
	}
}
.tag {
	@apply tw-bg-gray-500 tw-text-gray-200 tw-inline-block tw-relative tw-no-underline;
	border-radius: 3px 0 0 3px;
	height: 26px;
	line-height: 26px;
	padding: 0 20px 0 23px;
	//margin: 0 10px 10px 0;
	&::before {
		@apply tw-bg-white tw-absolute;
		border-radius: 10px;
		box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
		content: '';
		height: 6px;
		left: 10px;
		width: 6px;
		top: 10px;
	}
	&::after {
		@apply tw-bg-white tw-absolute tw-right-0 tw-top-0;
		border-bottom: 13px solid transparent;
		border-left: 10px solid theme('colors.gray.500');
		border-top: 13px solid transparent;
		content: '';
	}
	&.included {
		background-color: rgb(240, 253, 244);
		color: rgb(21, 128, 61);
		&::after {
			border-left-color: rgb(240, 253, 244);
		}
	}
	&.excluded {
		background-color: rgb(254, 242, 242);
		color: rgb(185, 28, 28);
		&::after {
			border-left-color: rgb(254, 242, 242);
		}
	}
}

