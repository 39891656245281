.openDeliverySummaryContainer {
	width: max-content;
	min-width: 200px;
	max-width: 100%;
}
.openDeliverySummaryBody {
	max-height: 50vh;
	overflow-y: auto;
	display: grid;
	grid-template-columns: max-content 1fr;
	grid-gap: 15px 30px;
}