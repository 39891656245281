.tip {
    @apply tw-text-left tw-z-20 tw-bg-gray-600;
    &.white {
        @apply tw-bg-white;
    }
    padding: 10px 10px 15px;
    border: 1px solid theme('colors.gray.400');
}

.caret-container {
    display: block;
    height: 15px;
    width: 15px;
}

.caret {
    @apply tw-bg-gray-600;
    width: 15px;
    height: 15px;
    transform: rotate(-45deg);
    border: 1px solid theme('colors.gray.400'); //#ccc;
    &.white {
        @apply tw-bg-white;
    }
}
.top {
    .caret-container {
        transform: translate(0,-50%);
    }
    .caret{
        transform: rotate(-45deg);
        &::after {
            @apply tw-bg-gray-600;
            display: inline-block;
            height:15px;
            width: 15px;
            transform: translate(1px, -4px);
            border: 1px solid transparent;
            content: ' ';
        }
    }
}
.left {
    .caret-container {
        @apply tw-absolute;
        top: 43% !important;
        left: -7px;
    }
    .caret {
        transform: rotate(45deg);
        &::after {
            @apply tw-bg-gray-600;
            display: inline-block;
            height:15px;
            width: 15px;
            transform: translate(1px, -4px);
            border: 1px solid transparent;
            content: ' ';
        }
    }

}
.bottom {
    .caret-container {
        transform: translate(0,50%);
    }
    .caret {
        @apply tw-border-t-0 tw-border-r-0;
        transform: rotate(135deg);
        &::after {
            @apply tw-bg-gray-600;
            display: inline-block;
            height:15px;
            width: 15px;
            transform: translate(1px, -4px);
            border: 1px solid transparent;
            content: ' ';
        }
    }
}

.caret.white {
    &::after {
        @apply tw-bg-white;
    }  
}
