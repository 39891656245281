.summary {
	@apply tw-cursor-pointer;
	text-indent: 0.75rem;
	&::marker {
		@apply tw-text-white;
	}
}
.summaryText {
	@apply tw--mt-6 tw-justify-between tw-pl-4 tw-pr-1 tw-bg-gray-300 tw-mb-2 tw-text-white tw-font-bold tw-text-lg;
}
.loading::after {
	@apply tw-overflow-hidden tw-inline-block tw-align-bottom tw-w-0;
	animation: ellipsis steps(4, end) 900ms infinite;
	content: '\2026'; // ascii code for the ellipsis character
}
@keyframes ellipsis {
	to {
		width: 1em;
	}
}
