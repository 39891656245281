.svg * {
	@apply tw-transition-all tw-duration-300;
}
.background,
.progress {
	fill: none;
}
.background {
	stroke: theme('colors.gray.400');
}
.progress {
	stroke: theme('colors.gray.DEFAULT');
	stroke-linecap: round;
	stroke-linejoin: round;
	&.finished {
		stroke: #166534;
	}
}
.text {
	@apply tw-font-bold tw-text-base;
	fill: theme('colors.gray.DEFAULT');
	&.finished {
		fill: #166534;
	}
}
.lineItem {
	@apply tw-flex tw-gap-4;
}